import { VisitorLogger } from '@wix/yoshi-flow-editor';
import {
  searchRequestStarted,
  searchRequestFinished,
} from '@wix/bi-logger-wix-search-widget/v2';
import {
  searchRequestStartedParams as BiSearchRequestStartedParams,
  searchRequestFinishedParams as BiSearchRequestFinishedParams,
} from '@wix/bi-logger-wix-search-widget/v2/types';
import {
  ISearchRequest,
  ISearchResponse,
  ISearchResponseTotals,
} from '@wix/client-search-sdk';

import {
  getBiAvailableFacets,
  getBiSelectedFacets,
  getBiTotals,
} from './utils';

type SearchRequestBiLoggerParams = {
  bi: VisitorLogger;
  correlationId?: string;
  origin: string;
  isDemoContent: boolean;
  searchRequest: ISearchRequest;
};

export type SearchRequestBiLogger = ReturnType<
  typeof createSearchRequestBiLogger
>;

export const createSearchRequestBiLogger = ({
  bi,
  correlationId,
  origin,
  isDemoContent,
  searchRequest,
}: SearchRequestBiLoggerParams) => {
  let startTime: number;

  const commonParams: Partial<
    BiSearchRequestStartedParams & BiSearchRequestFinishedParams
  > = {
    correlationId,
    documentType: searchRequest.documentType,
    isDemo: isDemoContent,
    orderingDirection: searchRequest.ordering?.ordering?.[0]?.direction,
    orderingFieldName: searchRequest.ordering?.ordering?.[0]?.fieldName,
    target: searchRequest.query,
  };

  const logSearchRequestFinished = (
    params: Partial<BiSearchRequestFinishedParams>,
  ) => {
    // 99:304 SearchResults - request result was received
    // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/304?artifactId=com.wixpress.wix-search-widget
    bi.report(
      searchRequestFinished({
        ...commonParams,
        ...params,
        loadingDuration: Date.now() - startTime,
        origin,
      }),
    );
  };

  return {
    started() {
      startTime = Date.now();
      // 99:303 searchResults - request was sent to server
      // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/303?artifactId=com.wixpress.wix-search-widget
      bi.report(searchRequestStarted(commonParams));

      return correlationId;
    },
    finished(
      searchResponse: ISearchResponse,
      searchResponseTotals: ISearchResponseTotals,
      documentIds: unknown,
    ) {
      logSearchRequestFinished({
        documentIds: JSON.stringify(documentIds),
        availableFacets: getBiAvailableFacets(searchRequest, searchResponse),
        selectedFacets: getBiSelectedFacets(searchRequest),
        resultCount: searchResponse.totalResults,
        resultsArray: getBiTotals(searchResponseTotals),
        success: true,
      });
    },
    failed(error: unknown) {
      logSearchRequestFinished({
        error: String(error),
        success: false,
      });
    },
  };
};
