import {
  ISearchRequest,
  ClientSearchSDK,
  ISearchResponseTotals,
  ISampleResponse,
  SearchDocumentType,
  ISampleResponseSample,
  IErrorResponse,
} from '@wix/client-search-sdk';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

const SAMPLES_SIZE = {
  MOBILE: 4,
  DESKTOP: 3,
};

export const getSamplesResponse = (
  searchRequest: ISearchRequest,
  shouldShowSamples: boolean,
  shouldLoadTotals: boolean,
  searchSDK: ClientSearchSDK,
  environment: ControllerFlowAPI['environment'],
  searchResponseTotals: ISearchResponseTotals,
  visibleCategories: string[],
  correlationId?: string,
) => {
  const shouldGetSamples = shouldShowSamples || shouldLoadTotals;

  const samplesPromise =
    shouldGetSamples && visibleCategories.length
      ? searchSDK.getSample(
          {
            query: searchRequest.query,
            limit:
              environment.isMobile && !environment.isEditorX
                ? SAMPLES_SIZE.MOBILE
                : SAMPLES_SIZE.DESKTOP,
            documentTypes: visibleCategories,
            includeSeoHidden: searchRequest.includeSeoHidden,
          },
          { correlationId },
        )
      : Promise.resolve({ results: [] });

  return Promise.all([
    shouldLoadTotals
      ? samplesPromise.then(
          (samplesResponse: ISampleResponse | IErrorResponse) => {
            if ('isError' in samplesResponse) {
              return samplesResponse;
            }
            const totalResults = samplesResponse.results.reduce(
              (sum, { total }) => sum + total,
              0,
            );
            return samplesResponse.results.reduce(
              (
                totals: ISearchResponseTotals,
                { documentType, total }: ISampleResponseSample,
              ) => {
                totals[documentType] = total;
                return totals;
              },
              {
                [SearchDocumentType.All]: totalResults,
              },
            );
          },
        )
      : searchResponseTotals,
    samplesPromise,
  ]);
};
