import {
  ISearchRequest,
  ISearchResponse,
  ISearchResponseTotals,
} from '@wix/client-search-sdk';
import { getVisibleCollections } from '../../../../../lib/productFacets';
import {
  convertProductFacetsRequestParamsToFilter,
  extractProductFacetsFromSearchResponse,
  hasAnySelectedProductFacets,
} from '../products';

export function getBiTotals(totals: ISearchResponseTotals | undefined): string {
  if (!totals) {
    return '0';
  }

  return JSON.stringify(totals);
}

export function getBiAvailableFacets(
  searchRequest: ISearchRequest,
  searchResponse: ISearchResponse,
) {
  if (searchResponse.facets.length === 0) {
    return;
  }

  const allFacets = extractProductFacetsFromSearchResponse(searchResponse);
  const selectedFacets =
    convertProductFacetsRequestParamsToFilter(searchRequest);

  const availableFacets = {
    ...allFacets,
    collections: getVisibleCollections(
      allFacets.collections,
      selectedFacets.collections ?? [],
    ),
  };

  return JSON.stringify(availableFacets);
}

export function getBiSelectedFacets(searchRequest: ISearchRequest) {
  const selectedFacets =
    convertProductFacetsRequestParamsToFilter(searchRequest);
  return hasAnySelectedProductFacets(selectedFacets)
    ? JSON.stringify(selectedFacets)
    : undefined;
}
