import { ISearchRequest, SearchDocumentType } from '@wix/client-search-sdk';

import { VisibleCategories } from './SearchResultsControllerStore.types';

export const withDocumentType = (
  searchRequest: ISearchRequest,
  visibleCategories: VisibleCategories,
): ISearchRequest => {
  if (
    searchRequest.documentType &&
    visibleCategories
      .map(([documentType]) => documentType)
      .includes(searchRequest.documentType)
  ) {
    return searchRequest;
  }

  const documentType = visibleCategories.length
    ? (visibleCategories[0][0] as SearchDocumentType)
    : SearchDocumentType.All;

  return {
    ...searchRequest,
    documentType,
  };
};
