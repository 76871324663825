import { SearchDocumentType } from '@wix/client-search-sdk';
import { VisibleCategories } from '../searchResultsControllerStore';

export function sortCategories(
  visibleCategories: VisibleCategories,
): SearchDocumentType[] {
  return [...visibleCategories]
    .sort((c1, c2) => c1[1].index - c2[1].index)
    .map(([documentType]) => documentType as SearchDocumentType);
}
