import { IPlatformAPI, IWebStorage } from '@wix/yoshi-flow-editor';

export enum BiStoreKey {
  SuggestionsCorrelation = 'Bi:SuggestionsCorrelation',
  SearchCorrelation = 'Bi:SearchCorrelation',
  SearchOrigin = 'Bi:SearchOrigin',
}

export class BiStore {
  private readonly storage: IWebStorage;

  constructor(platformApi: IPlatformAPI) {
    this.storage = platformApi.storage.session;
  }

  set(key: BiStoreKey, value: string): void {
    this.storage.setItem(key, value);
  }

  get(key: BiStoreKey): string {
    return this.storage.getItem(key) || '';
  }

  remove(key: BiStoreKey): void {
    this.storage.removeItem(key);
  }

  has(key: BiStoreKey): boolean {
    return this.storage.getItem(key) !== null;
  }
}
