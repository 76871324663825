import { IWixAPI } from '@wix/native-components-infra/dist/src/types/types';
import { ISearchProductDocument } from '@wix/client-search-sdk';

export const addProductToCart = async (
  { id, options, relativeUrl }: ISearchProductDocument,
  wixCodeApi: IWixAPI,
): Promise<void> => {
  const {
    stores: storesApi,
    location: locationApi,
    window: windowApi,
  } = wixCodeApi;

  // TODO: Also check for custom text fields and subscriptions here
  if (options.length) {
    locationApi.to?.(relativeUrl);
    return;
  }

  try {
    await storesApi?.cart.addProducts([{ productId: id, quantity: 1 }]);
  } catch {
    locationApi.to?.(relativeUrl);
    return;
  }

  if (windowApi.formFactor === 'Mobile') {
    locationApi.to?.('/cart-page');
  } else {
    storesApi?.cart.showMiniCart();
  }
};
