import { SearchDocumentType } from '@wix/client-search-sdk';
import { CategoryList } from '@wix/search-settings-client';

import { VisibleCategories } from './SearchResultsControllerStore.types';

export const getVisibleCategories = (
  categoryListSettings: CategoryList,
  availableDocumentTypes: SearchDocumentType[],
): VisibleCategories => {
  return Object.entries(categoryListSettings).filter(
    ([documentType, categorySettings]) =>
      categorySettings.visible &&
      availableDocumentTypes.includes(documentType as SearchDocumentType),
  );
};
